import React, { useEffect } from "react";

//@Packages
import { useSelector } from "react-redux";
import { useOidc } from "@axa-fr/react-oidc";

//@Store
import { RootState } from "../../store";

//@Functional Component
const LogoutStandAlone = () => {
	const { isAuthenticated } = useOidc();

	const { logoutIntiated } = useSelector((state: RootState) => state.global);

	useEffect(() => {
		if (!isAuthenticated) {
			if (window.location.pathname === "/logout") {
				window.location.href = "/";
			} else {
				window.location.href = "/logout";
			}
		} else {
			if (window.location.pathname === "/logout" && !logoutIntiated) {
				window.location.href = "/";
			}
		}
	}, []);

	return (
		<>
		</>
	)
};

export default LogoutStandAlone;