import React from "react";

//@Packages
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { useOidc } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router-dom";
import { Button, SignOutIcon } from "@forge/common";

//@Actions
import { setLogoutIntiated } from "../../actions";

//@Constants
import { LOCALE_CONST } from "../../utils/constants";

//@Styles
import "./styles.scss";

//@Functional Component
const LogoutButton = () => {
  const { logout } = useOidc();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    navigate("/logout");
    dispatch(setLogoutIntiated({ logoutIntiated: true }));
    logout();
  };

  return (
    <Button className="logout-btn-cls" variant="secondary" title="Logout" size="large" onClick={handleLogout}>
      <SignOutIcon className="signout-icon-cls" /> {t(LOCALE_CONST.logout)}
    </Button>
  );
};

export default LogoutButton;