import React from 'react';

import { Banner } from '@forge/common';

import { NotificationBannerProps } from '../../models/viewdevice';

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  variant, type, title, description, canClose = true, actions
}) => (
  <div className="grid grid-flow-row auto-rows-auto gap-4">
    <Banner variant={variant} type={type}
      title={title} description={description}
      canClose={canClose} actions={actions || []}
    />
  </div>
);

export default NotificationBanner;