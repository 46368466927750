const envSettings = window as any;
export class ConfigurationVariables {
  static LAUNCH_DARKLEY_CLIENT_ID = envSettings.ENV.LAUNCHDARKLYCLIENTID;
  static RBM_PORTAL_HOST_URL = envSettings.ENV.RBM_PORTAL_HOST_URL;
  static API_BASE_HOST_URL = envSettings.ENV.API_BASE_HOST_URL;
  static FORGE_IAM_CLIENT_ID = envSettings.ENV.FORGE_IAM_CLIENT_ID;
  static FORGE_IAM_REDIRECT_URI = envSettings.ENV.FORGE_IAM_REDIRECT_URI;
  static FORGE_IAM_SCOPE = envSettings.ENV.FORGE_IAM_SCOPE;
  static FORGE_IAM_AUTHORITY = envSettings.ENV.FORGE_IAM_AUTHORITY;
  static RBM_API_BASE_URL = envSettings.ENV.RBM_API_BASE_URL;
  static REMOTE_ACCESS_SECURE_CONNECT_URL = envSettings.ENV.REMOTE_ACCESS_SECURE_CONNECT_URL;
  static HONEYWELL_SUPPORT_EMAILID = envSettings.ENV.HONEYWELL_SUPPORT_EMAILID;
  static ORG_SITE_STATUS_NAME_LISTS = envSettings.ENV.ORG_SITE_STATUS_NAME_LISTS;
  static ORG_STATUS_LISTS = envSettings.ENV.ORG_STATUS_LISTS;
  static ORG_IN_PROGRESS_STATUS_MSG = envSettings.ENV.ORG_IN_PROGRESS_STATUS_MSG;
  static ORG_FAILED_STATUS_MSG = envSettings.ENV.ORG_FAILED_STATUS_MSG;
}
